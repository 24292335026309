<template>
  <div>
    <div class="content">
      <section class="clearfix" style="margin-bottom: 10px">
        <a-button @click="add" type="primary" v-if="priv('f_brand_create')">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t(editType ? '编辑品牌' : '新增品牌') }}
        </a-button>
      </section>
      <div class="form-content" style="">
        <a-row>
          <a-col :span="5">
            <a-input
              allowClear
              v-model="page.brandName"
              :placeholder="$t('请输入') + $t('品牌名称')"
            ></a-input>
          </a-col>
          <a-col :span="5" style="margin-left: 15px">
            <a-button type="primary" @click="fetchBrandList" v-if="priv('f_brand_list')">
              {{ $t(`查询`) }}
            </a-button>
          </a-col>
        </a-row>
      </div>
      <div class="table-content">
        <a-table
          :columns="newColumns"
          :rowKey="(record, index) => index"
          bordered
          :pagination="false"
          :data-source="tableData"
        >
          <template slot="action" slot-scope="text, record">
            <div class="editable-row-operations">
              <a @click="edit(record)">{{ $t('编辑') }}</a>
              <a-divider type="vertical" />
              <a class="text-danger" @click="confirmDelete(record)">{{ $t('删除') }}</a>
            </div>
          </template>
        </a-table>
        <!-- <div class="table-footer">
          <Pagination
            :total-count="page.total"
            :pageNo="page.pageNo"
            :length="page.pageSize"
            @paginate="paginate"
          />
        </div> -->
      </div>
    </div>

    <a-modal
      :visible="addVisible"
      :title="$t(editType ? '编辑品牌' : '新增品牌')"
      :confirmLoading="confirmLoading"
      @ok="save"
      @cancel="cancel"
      width="800px"
    >
      <a-form-model ref="ruleForm" :model="formData" :rules="rules" class="brand-form">
        <a-form-model-item ref="brandName" :label="$t('品牌名')" prop="brandName">
          <a-input v-model="formData.brandName" :disabled="editType === 'onlyModels'" />
        </a-form-model-item>
        <div class="model-list">
          <div class="model-list-header">
            <span>{{ $t('型号信息') }}</span>
            <a-button type="link" @click="addModelRow">
              <i class="icon iconfont icon-new"></i>
              {{ $t('添加型号') }}
            </a-button>
          </div>

          <div v-for="(model, index) in formData.modelList" :key="index" class="model-row">
            <a-input
              v-model="model.modelCode"
              :placeholder="$t('请输入型号编码')"
              style="width: 200px; margin-right: 10px"
            />
            <a-input
              v-model="model.modelName"
              :placeholder="$t('请输入型号名称')"
              style="width: 200px; margin-right: 10px"
            />
            <DateRangePicker
              :needhms="false"
              :placeholder="$t('上市时间')"
              :singleDatePicker="true"
              :startTime="model.timeToMarket"
              @get_date="
                (date) => {
                  model.timeToMarket = date
                }
              "
            />
            <a-button type="link" @click="removeModelRow(index)">
              {{ $t('删除') }}
            </a-button>
          </div>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import http from '@service/axios'
import api from '@service/api'
import { message, Modal } from 'ant-design-vue'
// import Pagination from '@component/pagination'
import { customCell } from '@/common'
import DateRangePicker from '@component/dateRangePicker'
export default {
  name: 'brandManage',
  components: { DateRangePicker },
  data() {
    return {
      form: {},
      editType: false,
      tableData: [],
      brandList: [],
      platform: undefined,
      page: {
        total: 0,
        brandName: undefined,
        pageSize: 10,
        pageNo: 1,
      },
      data: [],
      addVisible: false,
      confirmLoading: false,
      rules: {
        brandName: [
          {
            required: true,
            message: 'Please input Activity name',
            trigger: 'blur',
          },
        ],
      },
      formData: {
        brandName: '',
        brandId: '',
        timeToMarket: null,
        modelList: [{ modelCode: '', modelName: '', timeToMarket: null }],
      },
    }
  },
  mounted() {
    this.fetchBrandList()
  },
  computed: {
    newColumns() {
      const list = [
        {
          title: this.$t('品牌名称'),
          dataIndex: 'brandName',
          customCell: this.customCellHandle,
        },
        {
          title: this.$t('型号名称'),
          dataIndex: 'modelName',
          customCell: this.customCellHandle2,
        },
        {
          title: this.$t('型号编码'),
          dataIndex: 'modelCode',
          customCell: this.customCellHandle3,
        },
        {
          title: this.$t('上市时间'),
          dataIndex: 'timeToMarket',
          customCell: this.customCellHandle3,
        },
        {
          title: this.$t('操作'),
          dataIndex: 'action',
          width: 150,
          scopedSlots: { customRender: 'action' },
          customCell: this.customCellHandle,
        },
      ]
      return list
    },
  },
  methods: {
    customCellHandle(record, index) {
      return customCell(record, index, this.tableData, 'brandId')
    },
    customCellHandle2(record) {
      if (record.isAddModel) {
        return {
          attrs: {
            colSpan: 3,
            style: 'text-align: center; cursor: pointer; color: #1890ff;',
          },
          on: {
            click: () => this.editModels(record),
          },
        }
      }
      return {}
    },
    customCellHandle3(record) {
      if (record.isAddModel) {
        return {
          attrs: {
            colSpan: 0,
            style: 'display: none',
          },
        }
      }
      return {}
    },
    editModels(record) {
      this.editType = 'onlyModels'
      this.addVisible = true
      this.formData = {
        brandName: record.brandName,
        brandId: record.brandId,
        modelList: [...record.brandModelList, { modelCode: '', modelName: '', timeToMarket: null }],
      }
    },
    formatTableData(data) {
      let result = []
      data.forEach((item) => {
        const modelList = item.brandModelList.map((model) => ({
          ...model,
          ...item,
        }))
        if (modelList.length > 0) {
          result.push(...modelList)
          result.push({
            ...item,
            modelName: `+ ${this.$t('添加型号')}`,
            isAddModel: true,
          })
        } else {
          // 添加一个合并单元格的行用于"添加型号"
          result.push({
            ...item,
            isAddModel: true,
            modelName: `+ ${this.$t('添加型号')}`,
          })
        }
      })
      console.log('result: ', result)
      return result
    },
    edit(value) {
      this.editType = 'edit'
      this.addVisible = true
      this.formData = {
        brandName: value.brandName,
        brandId: value.brandId,
        modelList: value.brandModelList || [{ modelCode: '', modelName: '', timeToMarket: null }],
      }
    },
    add() {
      this.formData = {
        brandName: undefined,
        brandId: undefined,
        modelList: [],
      }
      this.addVisible = true
      this.editType = 'add'
    },
    addModelRow() {
      this.formData.modelList.push({
        modelCode: '',
        modelName: '',
        timeToMarket: null,
      })
    },

    removeModelRow(index) {
      this.formData.modelList.splice(index, 1)
    },
    save() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true
          const params = {
            brandName: this.formData.brandName,
            brandId: this.formData.brandId,
            timeToMarket: this.formData.timeToMarket,
            brandModelList: this.formData.modelList.filter(
              (model) => model.modelCode || model.modelName
            ),
          }

          http({
            url: api.saveBrand,
            type: 'post',
            data: params,
            hasLoading: false,
            success: (res) => {
              this.confirmLoading = false
              if (res.success) {
                message.success(this.editType ? this.$t('更新成功') : this.$t('创建成功'))
                this.addVisible = false
                this.fetchBrandList()
                this.editType = false
              }
            },
            fail: () => {
              this.confirmLoading = false
            },
          })
        }
      })
    },
    cancel() {
      this.addVisible = false
      this.editType = false
      this.formData = {
        brandName: '',
        brandId: '',
        modelList: [],
      }
    },
    paginate(data) {
      this.page.pageNo = data.pageNo
      this.page.pageSize = data.pageSize
      this.fetchBrandList()
    },
    fetchBrandList(value) {
      http({
        url: api.brandList,
        type: 'post',
        data: {
          name: value,
          pageNo: 1,
          pageSize: 50,
          merchantCode: this.$store.getters.merchantCode,
        },
        hasLoading: false,
        success: (res) => {
          const list = res.result.brandList
          this.tableData = this.formatTableData(list)
        },
      })
    },
    confirmDelete(record) {
      Modal.confirm({
        title: this.$t('确认删除'),
        content: this.$t('确定要删除品牌') + `"${record.brandName}"?`,
        okText: this.$t('确认'),
        cancelText: this.$t('取消'),
        onOk: () => {
          this.deleteBrand(record.brandId)
        },
      })
    },
    deleteBrand(brandId) {
      http({
        url: api.deleteBrand,
        type: 'post',
        data: { brandId },
        hasLoading: false,
        success: (res) => {
          if (res.success) {
            message.success(this.$t('删除成功'))
            this.fetchBrandList()
          }
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.form-content {
  width: 100%;
}
::v-deep .ant-form-item-label {
  width: 120px;
}
::v-deep .ant-form-item {
  width: 100%;
}
.brand-form {
  .model-list {
    margin-top: 20px;

    .model-list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .model-row {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
